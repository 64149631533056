.card {
  padding: 20px;
  text-align: start;
}

.keyboardPictureContainer {
  padding-left: 5vw;
}

.keyboardPicture {
  border-radius: 10px;
  width: 100%;
}

.switchPicture {
  position: absolute;
  align-items: center;
  right: 5vw;
  top: 20vh;
  width: 10vh;
  background-color: black;
  border-radius: 20%;
}
