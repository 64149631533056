.naaav {
  background-color: #282828;
  color: white;
  width: 100vw;
  padding: 15px;
  top: 0%;
}

.nav-link {
  color: white;
  font-size: 1rem;
}

.nav-link:focus,
.nav-link:hover {
  color: #ffff00;
}

.navbar-toggler {
  background-color: #555555;
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: #ffaa55;
}

.offcanvas-body {
  padding-top: 30vh;
  background-color: #282828;
  color: white;
}

.offcanvas {
  max-width: 70%;
}
