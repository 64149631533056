.bg-pic {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 20%;
  background-position-y: center;
  background-attachment: scroll;
  background-image: url("../media/typing_on_mini.gif");
}

#title {
  z-index: 999;
  position: relative;
  padding: 5%;
  padding-top: 10vh;
}

h3 {
  font-size: 2vh;
}

.orange {
  color: #ffaa55;
}

.yellow {
  color: #eeee55;
}

.emphasize {
  color: #ffaa77;
  font-size: xx-large;
}

.mainButton {
  max-width: 80%;
  min-width: 60%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.mainCol {
  padding: 5%;
}
