.card {
  color: black;
}

.sectional {
  animation: slide-up 2s ease;
}

@keyframes slide-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
